import React from 'react'
import Layout from '../components/Layout'
import Carousel from '../components/Carousel'
import Book from "../components/Book"
import Course from "../components/Course"
import { Grid, Container } from 'semantic-ui-react'
import { graphql } from 'gatsby'
import Ticker from 'react-ticker'

export const query = graphql`
  query {
    home:strapiHome(id: {ne: ""}) {
      Carrousel {
        Target
        Url
        Image {
          childImageSharp {
            sizes(maxWidth: 950) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      Course {
        ButtonText
        Content
        Image {
          childImageSharp {
            sizes(maxWidth: 950) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        Title
        Url
      }
      Book1 {
        ButtonText
        Images {
          url
        }
        Title
        TitleHighlight
        Url
      }
      Book2 {
        ButtonText
        Images {
          url
        }
        Title
        TitleHighlight
        Url
      }
      CourseLink {
        Text
        Url
      }
    }
  }
`

const IndexPage = ({ data, location }) => {
  const home = data.home;
  return (
    <Layout location={location}>
      <Carousel Images={home.Carrousel} style={{ paddingBottom: '1em' }} />
      <Grid stackable>
        <Grid.Row>
          <Container className="maininner">
            <Grid stackable>
              <Grid.Row className="books" columns={2}>
                <Grid.Column>
                  <Book book={home.Book1} />
                </Grid.Column>
                <Grid.Column>
                  <Book book={home.Book2} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Grid.Row>
        <Container className="maininner" style={{marginBottom: '15px'}}>
          <Grid stackable>
            <Grid.Row columns={3} >
              {home.Course.map((c, i) =>
                <Grid.Column key={i}>
                  <Course course={c}/>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          {home.CourseLink && home.CourseLink.Text && (
            <Ticker
            direction="toLeft"
            offset="100%"
            speed={7}
            move={true}
            mode='await'            
            >
              {() => (
                <a href={home.CourseLink.Url}>
                  <h1>{home.CourseLink.Text}</h1>  
                </a>
              )}
            </Ticker>
          )}
        </Container>
      </Grid>
    </Layout>
  )
}

export default IndexPage
