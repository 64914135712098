import React from "react"
import { Image, Divider } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'

const Course = ({ course }) => {
    return (
        <div className="course">
            {course.Url ? (
                <a href={course.Url}>
                    <Image fluid src={course.Image?.childImageSharp?.sizes?.src} />
                </a>
            ) : <Image fluid src={course.Image?.childImageSharp?.sizes?.src} />}
            
            <h3>{course.Title}</h3>
            <Divider />
            <ReactMarkdown>{course.Content}</ReactMarkdown>
            {course.Url && course.ButtonText && <a href={course.Url} className="ui icon button">{course.ButtonText}</a>}
        </div>
    )
}

export default Course
