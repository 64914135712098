import React from "react"
import { Image } from 'semantic-ui-react'

const Book = ({ book }) => {
    return (
        <div className="book">
            <div className="bookTitle">
                <h3>{book.Title}<span className="color">&nbsp;{book.TitleHighlight}</span></h3>
            </div>
            {book.Images.map((image, i) => <Image src={image.url} fluid key={i}/>)}
            <a href={book.Url} className="ui icon button">{book.ButtonText}</a>
        </div>
    )
}

export default Book
